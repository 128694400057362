import Alpine from 'alpinejs';
import '@lottiefiles/lottie-player';
import intersect from './directives/intersect';
import anim from './directives/anim';
import scroll from './magics/scroll';
import sendForm from './helpers/sendForm';
import isLg from './helpers/isLg';

// Loading...
const showApp = () => { document.querySelector("body.loading").classList.remove("loading")};
document.readyState === "loading" ? document.addEventListener("DOMContentLoaded", showApp) : showApp();

// Helpers
window.isLg = isLg;
window.sendForm = sendForm;

// Alpine Plugins
Alpine.plugin(intersect);
Alpine.plugin(anim);
Alpine.plugin(scroll);

// Start
window.Alpine = Alpine;
Alpine.start();