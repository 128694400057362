import {scrollTo} from "../magics/scroll";

export default (form) => {
    const statusEl = form.querySelector('.status');
    fetch(form.dataset.ep, {
        method: 'post',
        body: new FormData(form),
        headers: {
            'Accept': 'application/json'
        }
    }).then(response => {
        statusEl.classList.remove("hidden");
        if (response.ok) {
            statusEl.classList.remove("text-red");
            statusEl.classList.add("text-green");
            statusEl.innerHTML = "Merci ! Nous revenons très vite vers vous.";
            form.reset();
        } else {
            response.json().then(data => {
                statusEl.classList.add("text-red");
                statusEl.classList.remove("text-green");
                if (Object.hasOwn(data, 'errors')) {
                    statusEl.innerHTML = data["errors"].map(error => error["message"]).join(", ");
                } else {
                    statusEl.innerHTML = "Oups! Une erreur est survenue lors de l'envoi du formulaire."
                }
            })
        }
        scrollTo(statusEl);
    }).catch(error => {
        statusEl.classList.add("text-red");
        statusEl.classList.remove("text-green", "hidden");
        statusEl.innerHTML = "Oups! Une erreur est survenue lors de l'envoi du formulaire."
        scrollTo(statusEl);
    });
}