export default (Alpine) => {
    Alpine.directive('intersect', (el, {value, modifiers, expression}, {cleanup, evaluateLater}) => {

        // Valeurs par défaut
        const values = value !== null ? value.split(":") : [];
        const threshold = typeof values[0] !== "undefined" ? parseInt(values[0]) / 10 : 0;
        const rootMargin = typeof values[1] !== "undefined" ? values[1] : "0px 0px 0px 0px";
        const callback = expression ? evaluateLater(expression) : null;

        let pauseReactions = false;

        // On instancie l'observateur
        const observer = new IntersectionObserver(entries => {

            if (pauseReactions) return;
            pauseReactions = true;

            entries.forEach(entry => {

                if (modifiers.includes("enter") && entry.isIntersecting) {
                    modifiers.includes("once") && observer.disconnect();
                    callback && callback();

                } else if (modifiers.includes("leave")) {
                    modifiers.includes("once") && observer.disconnect();
                    callback && callback();
                }
            });

            setTimeout(() => {
                pauseReactions = false;
            }, 100);

        }, {
            rootMargin: rootMargin,
            threshold: threshold
        });

        // On observe l'élément.
        setTimeout(() => observer.observe(el), 300);

        // On supprime l'observation.
        cleanup(() => {
            observer.disconnect();
        })
    });
}