export default (Alpine) => {
    Alpine.directive('anim', (el, {value, modifiers, expression}, {cleanup}) => {

        const timeout = Array.isArray(modifiers) ? modifiers[0] : 0;
        expression = expression || "anim";

        el.classList.add(expression);
        setTimeout(() => el.classList.add('ready'), 100);

        let pauseReactions = false;
        const observer = new IntersectionObserver(entries => {

            if (pauseReactions) return;
            pauseReactions = true;

            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setTimeout(() => el.classList.add("ate"), timeout);
                }
            });

            setTimeout(() => {
                pauseReactions = false;
            }, 100);

        });

        // On observe l'élément.
        setTimeout(() => observer.observe(el), 300);

        // On supprime l'observation.
        cleanup(() => {
            observer.disconnect();
        })

    });
}