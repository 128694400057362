import smoothscroll from 'smoothscroll-polyfill';

export function scrollTo(target) {

    const originalTarget = target;

    // kick off the polyfill !
    smoothscroll.polyfill();

    const targetEl = typeof target === "string" ? document.querySelector(target) : target;

    // If we got an element, get the y coordinate relative to the document
    // This could happens if we trasform a selector or if we pass an Element in,
    // for example using $ref['something']
    if (targetEl instanceof Element) {
        target = Math.floor(targetEl.getBoundingClientRect().top + window.pageYOffset)
    }

    // If target has been converted to the y coordinate or was an object to begin with
    // we transform it to a ScrollToOptions dictionary
    if (Number.isInteger(target)) {
        target = {
            top: target,
            behavior: 'smooth', // default to smooth
        }
    }

    // At this point target should be either be converted to a ScrollToOptions dictionary
    // or should have been an object to begin with. If it isn't, it's time to give up.
    if (typeof target !== 'object') {
        throw Error('Unsupported $scroll target: ', originalTarget);
    }

    // Let's scroll eventually
    window.scroll(target);
}

export default (Alpine) => {
    Alpine.magic('scroll', () => target => scrollTo(target));
}